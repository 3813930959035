import React, { useEffect } from "react";
import { camelCase, paramCase } from "change-case";
import PropTypes from "prop-types";
import ServicesContainer from "../../containers/ServicesContainer";
import PageBanner from "../../sections/PageBanner";
import PageIntro from "../../sections/PageIntro";
import HorizontalCard from "../../sections/HorizontalCard";
import SimpleImageSlider from "../../sections/SimpleImageSlider";
import scrollToSection from "../../utilities/scroll-to";
import StickyScrollToNavbar from "../StickyScollToNavbar";

const ServicesPage = ({ location }) => {
  const { hash } = location;

  useEffect(() => {
    setTimeout(() => {
      scrollToSection(hash.slice(1));
    }, 1000);
  }, [hash]);

  return (
    <ServicesContainer>
      {(servicesData, serviceData) => {
        const pageName = "services";
        const servicesContent = servicesData
          .map((ele) => (ele.id === pageName ? ele : null))
          .find((el) => el);
        const { pageId, pageBanner, introduction } = servicesContent;

        let allServices = serviceData
          .filter((service) => !service.hideService)
          .map((service) => ({
            ...service,
            summary: {
              ...service.summary,
              actionButtons:
                service.summary.actionButtons &&
                service.summary.actionButtons.map((btn) => ({
                  ...btn,
                  buttonPath: `${btn.buttonPath}/?subject=${camelCase(
                    service.title
                  )}`,
                })),
            },
          }));

        const servicesOrder = servicesContent.servicesOrder.map(
          (serviceData) => serviceData.serviceId
        );
        allServices = allServices.sort((a, b) => {
          const A = a.serviceId;
          const B = b.serviceId;
          return servicesOrder.indexOf(A) > servicesOrder.indexOf(B) ? 1 : -1;
        });

        allServices.map((service) => ({
          ...service,
          summary: {
            ...service.summary,
            actionButtons: service.summary.actionButtons.unshift({
              buttonLabel: "Learn More",
              buttonPath: `/services/${paramCase(service.title)}`,
              buttonType: "primary",
              externalLink: false,
              hideButton: !(
                service.summary.actionButtons &&
                service.summary.actionButtons.length > 0 &&
                !service.summary.hideDetailButton
              ),
              simpleLink: false,
            }),
          },
        }));

        return (
          <>
            {pageBanner && !pageBanner.hideSection && (
              <PageBanner
                bannerContent={pageBanner.textContent}
                bgImage={pageBanner.backgroundImage}
              />
            )}
            {introduction && !introduction.hideSection && (
              <PageIntro data={introduction} page={pageId} />
            )}
            {allServices && allServices.length > 0 && (
              <>
                <StickyScrollToNavbar
                  links={allServices.map((i) => ({ label: i.title }))}
                />
                {allServices.map((service, index) => (
                  <>
                    <HorizontalCard
                      key={`${camelCase(service.title)}-${index}`}
                      data={{
                        actionButtons: service.summary.actionButtons,
                        image: service.introduction.images[0].image,
                        serviceId: service.serviceId,
                        textContent: {
                          content: service.summary.content,
                          heading: service.summary.heading,
                        },
                      }}
                      hashName={camelCase(service.title)}
                      isEven={(index + 1) % 2 === 0}
                      page={pageId}
                    />
                    {service?.relativeImages?.length > 0 && (
                      <SimpleImageSlider data={service.relativeImages} />
                    )}
                  </>
                ))}
              </>
            )}
          </>
        );
      }}
    </ServicesContainer>
  );
};

ServicesPage.propTypes = {
  location: PropTypes.shape({
    hash: PropTypes.string,
  }),
};

ServicesPage.defaultProps = {
  location: null,
};

export default ServicesPage;
