import React from "react";
import { Router } from "@reach/router";
import ServicesPage from "../components/ServicesPage";
import NotFoundPage from "./404";

const aboutPageRouter = (props) => (
  <Router>
    <ServicesPage {...props} path="/services" />
    <NotFoundPage default />
  </Router>
);

export default aboutPageRouter;
