import React from "react";
import PropTypes from "prop-types";
import Box from "../../base/Box";
import MarkdownToHtml from "../../components/MarkdownToHtml";
import AspectRatio from "../../components/AspectRatio";
import BgImage from "../../components/BgImage";
import ActionButtons from "../../components/ActionButtons";
import Section from "../../components/Section";
import Image from "../../components/Image";
import { SERVICE_HEIGHT_EXCEPTION } from "../../utilities/constants";

const HorizontalCard = ({
  sectionSx,
  data,
  containerSx,
  page,
  imageRatio,
  imageSx,
  textContentSx,
  isEven,
  ...rest
}) => (
  <Section
    containerSx={{
      alignItems: "center",
      display: "flex",
      flexDirection: ["column", null, null, isEven ? "row-reverse" : "row"],
      maxWidth: [null, "maxWidths.sm", null, "maxWidths.lg"],
      ...containerSx,
    }}
    sx={{
      bg: "white",
      mb: 0,
      mt: 0,
      ...sectionSx,
    }}
    {...rest}
  >
    {data.image && (
      <Box
        sx={{
          display: "flex",
          flex: "auto",
          justifyContent: ["center", null, null, isEven ? "" : "flex-end"],
          width: ["100%", null, null, "50%"],
        }}
      >
        <AspectRatio
          ratio={imageRatio}
          sx={{
            height: Object.keys(SERVICE_HEIGHT_EXCEPTION).includes(
              data.serviceId
            )
              ? SERVICE_HEIGHT_EXCEPTION[data.serviceId]
              : "auto",
            maxWidth: "100%",
            // mx: 4,
          }}
        >
          {page ? (
            <BgImage
              name={data.image}
              page={page}
              sx={{
                backgroundPosition: "center",
                backgroundSize: "contain",
                position: "absolute",
                ...imageSx,
              }}
            />
          ) : (
            <Image
              img={data.image}
              sx={{
                height: "auto",
                objectFit: "cover",
                width: "100%",
                ...imageSx,
              }}
            />
          )}
        </AspectRatio>
      </Box>
    )}
    <Box
      sx={{
        flex: "auto",
        maxWidth: [null, null, null, "50%"],
        [isEven ? "pr" : "pl"]: [null, null, null, 4],
      }}
    >
      {data.textContent && (
        <MarkdownToHtml
          heading={data.textContent.heading}
          source={data.textContent.content}
          sx={{
            h2: {
              color: "text.heading",
              fontWeight: "medium",
            },
            mt: [4, null, null, 0],
            ...textContentSx,
          }}
        />
      )}
      {data.actionButtons &&
        data.actionButtons.length > 0 &&
        data.actionButtons.some((i) => !i.hideButton && i.buttonLabel) && (
          <ActionButtons buttons={data.actionButtons} />
        )}
    </Box>
  </Section>
);

HorizontalCard.propTypes = {
  containerSx: PropTypes.shape({}),
  data: PropTypes.shape({
    actionButtons: PropTypes.arrayOf(PropTypes.shape({})),
    image: PropTypes.string.isRequired,
    relativeImages: PropTypes.arrayOf(
      PropTypes.shape({
        images: PropTypes.string,
      })
    ),
    serviceId: PropTypes.string,
    textContent: PropTypes.shape({
      content: PropTypes.string,
      heading: PropTypes.string,
    }).isRequired,
  }).isRequired,
  imageRatio: PropTypes.arrayOf(
    PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.number), PropTypes.number])
  ),
  imageSx: PropTypes.string,
  isEven: PropTypes.bool,
  page: PropTypes.string.isRequired,
  sectionSx: PropTypes.shape({}),
  sx: PropTypes.shape({}),
  textContentSx: PropTypes.string,
};

HorizontalCard.defaultProps = {
  containerSx: {},
  imageRatio: [[16, 9], null, null, [1, 1]],
  imageSx: {},
  isEven: false,
  sectionSx: {},
  sx: {},
  textContentSx: {},
};

export default HorizontalCard;
